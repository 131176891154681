.headline
	color: inherit
	display: block
	+hyphensauto
	position: relative

h1.headline

h2.headline

h3.headline

h4.headline

h5.headline

h6.headline