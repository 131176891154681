// COMMON
$common_fontfolder:				'../../fonts/'



// COLORS
$color-black:					#000			
$color-white:					#FFF

$color-grey:                    #F2F2F2
$color-grey-mid:                #666
$color-grey-dark:               #333

$color-green:                   #3CB482
$color-green-dark:              #2C8860
$color-blue:                    #6fa7fc


// LAYERING
$layer__header-menu:            9980
$layer__header-menu-button:     9981


// MEASUREMENTS AND BREAKPOINTS
$maxwidth:						1280px
$tabletbp:						1024px
$mobilebp:						768px
$minimobilebp:					374px
$minwidth:						320px

// CSS VARIABLES

:root
    --green:                        #{$color-green}
    --green-dark:                   #{$color-green-dark}
    --blue:                         #{$color-blue}

    --theme-background:             #{$color-grey}

    --color-primary:                #{$color-green}
