@mixin wrapper
    margin-left: auto
    margin-right: auto
    max-width: $maxwidth
    padding-left: 4rem
    padding-right: 4rem

    @media screen and (max-width: $mobilebp)
        padding-left: 2rem
        padding-right: 2rem

