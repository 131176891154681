.header
    background-color: $color-white
    color: $color-green
    display: block
    position: relative
    +transition(background-color .3s ease, color .3s ease)

    @media screen and (max-width: $tabletbp)
        background-color: $color-green
        color: $color-white

        &.header--menu-active
            background-color: $color-white
            color: $color-green

    &--logo
        font-size: 8rem
        margin: 0 auto

        @media screen and (max-width: $tabletbp)
            font-size: 6rem
            margin-left: 0


    button.header--menu-button
        display: none
        padding-left: 3.6rem
        &::before,
        &::after
            background-color: currentColor
            +borderradius(.1rem)
            content: ''
            display: block
            height: .2rem
            left: 1.5rem
            position: absolute
            top: 50%
            +transform(translateY(-50%))
            +transition(margin-top .3s ease, transform .3s ease, width .3s ease)
            width: 1.4rem
            z-index: 2
        &::before
            margin-top: -.2rem
        &::after
            margin-top: .2rem
            width: 1rem
        @media screen and (max-width: $tabletbp)
            display: inline-flex

    &--menu-active button.header--menu-button
        &::before,
        &::after
            margin-top: 0
            +transform(translateY(-50%) rotate(45deg))
            width: 1.4rem
        &::after
            +transform(translateY(-50%) rotate(-45deg))

    &__inner
        align-items: center
        display: flex
        min-height: 10rem
        position: relative

        @include wrapper

        @media screen and (max-width: $tabletbp)
            max-height: 8rem
            min-height: 8rem
    
    &__menu
        display: block
        +getComponent('nunito-sb')
        font-size: 1.6rem
        position: absolute
        &-list
            align-items: center
            display: flex
            margin: 0 -1.5rem
            position: relative

            &-item
                color: currentColor
                margin: 1rem 1.5rem

        &-link
            display: block
            position: relative
            +transition(color .3s ease)
            &::after
                background-color: currentColor
                bottom: -1rem
                content: ''
                display: block
                height: .4rem
                left: 50%
                position: absolute
                +transform(translateX(-50%))
                +transition(width .3s ease)
                width: 0
            &.header__menu-link--active
                pointer-events: none
                &::after
                    width: 100%
            
            @media (hover:hover)
                &:hover
                    &::after
                        width: 100%
        @media screen and (max-width: $tabletbp)
            @include wrapper

            background-color: $color-white
            color: $color-green
            height: calc(100vh - 8rem)
            left: 0
            max-height: calc(100vh - 8rem)
            opacity: 0
            top: 8rem
            +transform(translateY(calc(-100% - 10rem)))
            +transition(opacity .2s ease, transform .3s ease)
            width: 100%
            z-index: $layer__header-menu

            .header--menu-active &
                opacity: 1
                +transform(translateY(0))

            &-list
                align-items: center
                flex-direction: column
                font-size: 1.8rem
                justify-content: flex-start
                max-height: 100%
                min-height: 100%
                overflow-x: hidden
                overflow-y: auto
                padding: 4rem 0 3rem 0
            &-link
                display: inline-block
                margin: 1.5rem 0

