.footer
    background-color: $color-white
    color: $color-green
    +borderradiustl(4rem)
    +borderradiustr(4rem)
    display: flex
    flex-direction: column
    +getComponent('nunito-bd')
    min-height: 23rem
    padding-top: 4rem
    position: relative
    @media screen and (max-width: $tabletbp)
        padding-top: 3rem

    &--logo
        font-size: 38rem
        height: auto
        @media screen and (max-width: $tabletbp)
            display: none
    &--logo-mobile
        display: none
        font-size: 15rem
        height: auto
        margin-top: 3rem
        @media screen and (max-width: $tabletbp)
            display: block

    &__inner
        align-items: center
        display: flex
        flex-grow: 1
        justify-content: space-between
        padding-bottom: 4rem
        position: relative
        width: 100%

        @include wrapper

        @media screen and (max-width: $tabletbp)
            flex-wrap: wrap
            justify-content: center
    
    &__menus
        display: block
        position: relative

        @media screen and (max-width: $tabletbp)
            width: 100%

    &__main-menu
        color: $color-grey-mid
        display: block
        font-size: 1.6rem
        margin: 1.2rem 0
        position: relative
        ul
            display: flex
            justify-content: flex-end
            margin: 0 -1rem
            position: relative
            li
                margin: 1rem
                a
                    color: currentColor
                    display: inline-block
                    +transition(color .3s ease)
                    position: relative
                    &::after
                        background-color: currentColor
                        bottom: -.8rem
                        content: ''
                        display: block
                        height: .4rem
                        left: 50%
                        position: absolute
                        +transform(translateX(-50%))
                        +transition(width .3s ease)
                        width: 0
                    @media (hover:hover)
                        &:hover
                            color: $color-green
                            &::after
                                width: 100%
                &.active
                    a
                        pointer-events: none
                        &::after
                            width: 100%
        @media screen and (max-width: $tabletbp)
            font-size: 1.4rem
            width: 100%
            ul
                flex-wrap: wrap
                justify-content: center
                li
                    margin: 1rem 0
                    text-align: center
                    width: 100%
    
    &__sub-menus
        align-items: center
        display: flex
        justify-content: flex-end
        margin: 1.2rem 0
        min-height: 8rem
        position: relative

        @media screen and (max-width: $tabletbp)
            flex-wrap: wrap
            min-height: none

    &__sub-menu
        display: block
        font-size: 1.4rem
        position: relative
        ul
            display: flex
            margin: 0 -1rem
            position: relative
            li
                margin: 1rem
                a
                    color: currentColor
                    display: inline-block
                    position: relative
                    +transition(color .3s ease)
                    @media (hover:hover)
                        &:hover
                            color: $color-green-dark
                &.active
                    a
                        color: $color-green-dark
                        pointer-events: none
        @media screen and (max-width: $tabletbp)
            width: 100%
            ul
                flex-wrap: wrap
                justify-content: center
                li
                    margin: 1rem 0
                    text-align: center
                    width: 100%
    
    &__social-menu
        display: block
        margin-left: 4rem
        position: relative
        ul
            display: flex
            margin: 0 -1rem
            position: relative
            li
                color: currentColor
                margin: 1rem
                +transition(color .3s ease)
                @media (hover:hover)
                    &:hover
                        color: $color-green-dark
        @media screen and (max-width: $tabletbp)
            margin: 2rem auto 0 auto

    &__bottom
        align-items: center
        background-color: $color-green
        color: $color-white
        display: flex
        +getComponent('nunito-md')
        font-size: 1.4rem
        height: 4rem
        max-height: 4rem
        position: relative

    &__copyright
        display: block
        position: relative
        width: 100%

        @include wrapper

        @media screen and (max-width: $tabletbp)
            text-align: center