button
    &.button
        align-items: center
        background-color: $color-grey
        border-radius: 3rem
        color: $color-grey-dark
        cursor: pointer
        display: inline-flex
        font-size: 1.8rem
        +getComponent('nunito-bd')
        max-height: 6rem
        min-height: 6rem
        padding: 2.5rem 3rem
        position: relative
        +transition(transform .3s ease)
        +noselect()
        white-space: nowrap

        .button
            &__hover
                background-color: rgba(10, 10, 10, .05)
                +borderradius(3rem)
                content: ''
                display: block
                height: 0
                left: 50%
                pointer-events: none
                position: absolute
                top: 50%
                +transform(translate(-50%,-50%))
                +transition(height .15s ease, width .3s ease)
                width: 0
                z-index: 1
            &__text
                display: inline-block
                position: relative
                z-index: 2

        @media screen and (max-width: $mobilebp)
            +borderradius(2.2rem)
            font-size: 1.4rem
            max-height: 4.4rem
            min-height: 4.4rem
            padding: 1.5rem

            .button__hover
                +borderradius(2.2rem)

        @media (hover:hover)
            &:hover
                +transform(scale(1.1))
                .button__hover
                    height: 100%
                    width: 100%


        &--primary
            background-color: $color-green
            +boxshadow(rgba($color-green, .3) 0 .5rem 1rem 0)
            color: $color-white
            
            .button__hover
                background-color: rgba($color-white, .1)
        &--white
            background-color: $color-white
            color: $color-green-dark

