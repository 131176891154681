.icon-link
    align-items: center
    color: currentColor
    display: flex
    font-size: 4rem
    justify-content: center
    text-align: center
    
    &__icon
        color: currentColor
        font-size: 1em
        +transition(font-size .3s ease)

    &:focus
        .icon-link__icon
            font-size: 1.1em

    @media (hover:hover)
        &:hover
            .icon-link__icon
                font-size: 1.1em