html
	font-size: 10px
	overflow-x: hidden
	width: 100%

body
	background-color: var(--theme-background)
	font-size: 1.4rem
	+getComponent('nunito-md')

#site
	display: flex
	flex-direction: column
	min-height: 100vh
	position: relative
	width: 100%

#content
	display: block
	flex-grow: 1
	position: relative
	width: 100%